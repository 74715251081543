@import 'variables.scss';

.title {
    color: #000000;
    padding-bottom: 18px;
    font-weight: 500;
    font-size: 26px;
    line-height: 150%;
}

.fields {
    & > div {
        padding-bottom: 10px;
    }

    .fieldGroup {
        display: flex;

        & > div:nth-child(1) {
            padding-right: 8px;
        }
    }
}

.agreement {
    color: #233747;
    letter-spacing: -0.63px;
    font-size: 14px;
    line-height: 150%;
    padding-bottom: 8px;
    a {
        color: green;
        text-decoration: none;
    }
}

.subTitle {
    color: #233747;
    letter-spacing: -0.63px;
    font-size: 14px;
    line-height: 150%;
    padding: 10px 0;

    span {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
    }
}

.createButton {
    padding-bottom: 18px;

    & > button {
        background: #82bd73;
        border: none;
        border-radius: 8px;
        color: #ffffff !important;
        cursor: pointer;
        height: 56px;
        width: 100%;
        font-family: 'Work Sans', sans-serif !important;
        text-transform: unset;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;

        &:hover {
            background: #6dac5d;
        }

        &:disabled {
            background: #daebd5;
        }
    }
}

.resetPasswordText {
    margin-bottom: 18px;
}

.divider {
    display: flex;
    align-items: center;
    padding-bottom: 18px;

    .line {
        background: #e5eaed;
        height: 1px;
        width: 100%;
    }

    .text {
        color: #909da6;
        letter-spacing: -0.63px;
        font-size: 14px;
        line-height: 150%;
        padding: 0 8px;
    }
}

.google {
    padding-bottom: 10px;

    button {
        display: flex;
        background: #4285f4;
        border: none;
        border-radius: 8px;
        align-items: center;
        width: 100%;
        min-height: 56px;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        padding: 0;

        & > div {
            margin: 0 13%;
        }
    }
}

.facebook {
    padding-bottom: 10px;
    & > span {
        & > button {
            min-height: 56px;
            border-radius: 8px !important;
            display: flex;
            color: #ffffff;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            width: 100%;
            text-transform: unset;
            height: 56px;
            padding: 0;
            align-items: center;
            font-family: 'Work Sans', sans-serif !important;

            & > i {
                margin: 0 13% !important;
                width: 18px;
                height: 18px;
            }
        }
    }
}
.newMember {
    margin: 7px 0 15px 0;
    font-size: 13px;
    strong {
        color: #7bc941;
        font-size: 16px;
        cursor: pointer;
    }
}
.forgot {
    margin-top: 15px;
    font-size: 13px;
    cursor: pointer;
}

.forgotText {
    margin: 0px;
}

@media screen and (max-width: $mq-small-tablet) {
    .google {
        button {
            & > div {
                margin: 0 17%;
            }
        }
    }

    .facebook {
        & > span {
            & > button {
                & > i {
                    margin: 0 17% !important;
                }
            }
        }
    }
}

@media screen and (max-width: $mq-mobile) {
    .title {
        font-size: 24px;
    }

    .google {
        button {
            & > div {
                margin: 0 10%;
            }
        }
    }

    .facebook {
        & > span {
            & > button {
                & > i {
                    margin: 0 10% !important;
                }
            }
        }
    }
}
