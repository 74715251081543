@import 'variables.scss';
.footer {
    background-color: #f7fafc;
    @media all and (max-width: $mq-tablet) {
        padding-bottom: 68px;
    }
}
.footerUnPadding {
    background-color: #f7fafc;
}
.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px;
    @media all and (max-width: $contentWidth) {
        padding: 0 24px 0 40px;
    }
    @media all and (max-width: $mq-mobile) {
        padding: 0 23px;
    }
}
.containerBottom {
    padding: 0 30px;
    background-color: #eff5f9;
    @media all and (max-width: $mq-tablet) {
        padding: 0 24px 0 40px;
    }
    @media all and (max-width: $mq-mobile) {
        padding: 0 20px;
    }
}
.footerTop {
    display: flex;
    justify-content: space-between;
    padding: 35px 0 46px 0;
    align-items: center;
    @media all and (max-width: $mq-small-tablet) {
        padding: 22px 0;
        flex-wrap: wrap;
    }
}
.logo {
    & > div {
        width: 146px;
        height: 40px;
        cursor: pointer;
    }
}
.message {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.69px;
    max-width: 486px;
    display: flex;
    text-align: initial;
    @media all and (max-width: $mq-medium-tablet) {
        max-width: 295px;
        font-size: 16px;
    }
    @media all and (max-width: $mq-small-tablet) {
        max-width: 295px;
        padding: 23px 32px 15px;
        font-weight: 500;
        font-size: 15px;
        line-height: 146%;
        display: none;
    }
    & > p {
        margin: unset;
        padding: unset;
    }
}
.icons {
    display: flex;
}
.instagram {
    cursor: pointer;
    & > div {
        width: 32px;
        height: 32px;
    }
}
.facebook {
    cursor: pointer;
    padding: 0 32px;
    & > div {
        width: 32px;
        height: 32px;
    }
    @media all and (max-width: $mq-mobile) {
        padding: 0 15px;
    }
}
.frame {
    cursor: pointer;
    & > div {
        width: 32px;
        height: 32px;
    }
}
.footerBottom {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    @media all and (max-width: $mq-small-tablet) {
        padding: 19px 0;
    }
}
.footerBottomLeft {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #909da6;
}
.footerBottomRight {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #909da6;
    @media all and (max-width: $mq-small-tablet) {
        display: none;
    }
}
.service {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #909da6;
    text-decoration: none;
}
.privacy {
    padding-left: 32px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #909da6;
    text-decoration: none;
}
.bottom {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    padding-top: 52px;
    text-align: start;
    display: none;
    @media all and (max-width: $mq-small-tablet) {
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
    }
}
.label1 {
    display: flex;
    cursor: pointer;
    color: #909da6;
    text-decoration: none;
    @media all and (max-width: $mq-small-tablet) {
        padding-right: 50px;
    }
    @media all and (max-width: $mq-mobile) {
        padding-right: 25px;
    }
}
.label2 {
    display: flex;
    cursor: pointer;
    color: #909da6;
    text-decoration: none;
    text-align: start;
    // @media all and (max-width: 375px) {
    //     padding-top: 15px;
    // }
}
