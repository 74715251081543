.imageContainer {
    position: relative;
}
.loadingContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image {
    width: auto;
    height: 200px;
    margin: auto !important;
}

@import 'variables.scss';
.imageOverlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
    text-align: center;
    padding: 20px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    @media all and (max-width: $mq-mobile) {
        font-size: larger;
    }
    @media all and (max-width: $mq-small-mobile) {
        font-size: large;
    }
}

.tooltip {
    text-transform: capitalize;
}

.contentContainer {
    padding: 0px !important;
    padding-bottom: 20px !important;
    align-content: center;
}

.close {
    align-self: flex-end;
}

.hr {
    width: 100px;
    align-self: center;
    margin-bottom: 20px !important;
}

.title {
    text-align: center;
    padding-bottom: 5px !important;
}
.btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto !important;
}

.btn {
    background-color: #f1f1f1 !important;
    justify-content: space-around !important;
    width: 250px;
    margin: 5px auto !important;
}

.btnText {
    width: 160px;
}

.copyIcon {
    color: #686b60;
}

.hrMenu {
    width: 110px;
    margin: 10px 15px !important;
}

.shareMenuContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.shareBtnContainer {
    margin: 32px;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.fab {
    background-color: white !important;
    width: 48px !important;
    height: 48px !important;
    margin: auto 24px !important;
}

.shareBtnContainerMenu {
    min-width: 250px !important;
    max-height: none !important;
}

.popper {
    z-index: 100 !important;
}

.listItemIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal {
    & > div:nth-child(3) {
        & > div {
            width: 100%;
        }
    }
    .modalHeader {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        .title {
            margin: 0 auto;
        }
        .close {
            margin: 0 0 10px 10px;
        }
    }
}
