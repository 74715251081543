@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

.App {
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    font-family: 'Work Sans', sans-serif !important;
}

.MuiFormLabel-root,
.MuiFormHelperText-root {
    font-family: 'Work Sans', sans-serif !important;
}

body {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    font-family: 'Work Sans', sans-serif !important;
}
