.notificationContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .notificationWrap {
        display: flex;
        align-items: center;

        .iconWrap {
            margin-right: 15px;

            svg {
                font-weight: 600;
            }

            .successIcon {
                color: #39c55e;
            }

            .infoIcon {
                color: #4382f6;
            }

            .warningIcon {
                color: #f59e0b;
            }

            .errorIcon {
                color: #ef4444;
            }
        }

        .content {
            .title {
                text-transform: capitalize;
                font-weight: 600;
                font-size: 14px;
            }
            .message {
                font-size: 12px;
            }
        }
    }
}

.notification {
    background-color: #fff;
    border-radius: 5px;
    width: 420px;
    margin-top: 60px;
}

.warning {
    border-left: 6px solid #f59e0b;
}

.success {
    border-left: 6px solid #39c55e;
}

.information {
    border-left: 6px solid #4382f6;
}

.error {
    border-left: 6px solid #ef4444;
}
