@import 'variables.scss';
.ingredientListManagement {
    text-align: left;

    .accordion {
        padding: 0 55px 0 43px;
        box-shadow: unset;
    }

    .expandIcon {
        background: #f7fafc;
        border-radius: 8px;
        color: #0d2120;
        position: absolute;
        right: 0;
    }

    .accordionSummary {
        max-width: unset !important;
        padding: 23px 0 12px 0;
        border-bottom: 1px solid #eff5f9;
    }

    .content {
        margin: 0;
    }

    .accordionHeader {
        display: flex;
        width: 100%;
        align-items: center;

        .nameHeader {
            width: 35%;
            display: flex;
            align-items: center;

            .name {
                color: #0d2120;
                letter-spacing: -0.69px;
                font-weight: 500;
                font-size: 24px;
                line-height: 150%;
            }
        }

        .subTitleHeader {
            width: 65%;
            display: flex;
            align-items: center;
            padding-right: 100px;

            & > div {
                width: 50%;
                display: flex;
                align-items: center;
                color: #233747;
                letter-spacing: -0.4px;
                font-size: 14px;
                line-height: 146%;
                justify-content: center;

                & > div:nth-child(1) {
                    padding-right: 9px;
                }
            }

            & > div:nth-child(2) {
                margin: 0 auto;
            }
        }
    }

    .accordionDetails {
        padding: 0;
        display: block;
    }

    .ingredientItem {
        padding: 12px 0;
        border-bottom: 1px solid #eff5f9;
    }
}

@media screen and (max-width: $mq-mobile) {
    .ingredientListManagement {
        .accordion {
            padding: 0 20px;
        }

        .expandIcon {
            padding: 4px;
            margin-right: 0;
        }

        .accordionSummary {
            padding: 20px 0 16px;
            border-bottom: unset;
        }

        .accordionHeader {
            display: flex;
            width: 100%;
            align-items: center;

            .nameHeader {
                width: 100%;
            }
        }

        .ingredientItem {
            padding: 0 0 16px;
            border-bottom: unset;
        }
    }
}
