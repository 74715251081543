@import 'variables.scss';

.container {
    box-shadow: none;
    margin-top: 5px !important;

    @media screen and (max-width: $mq-tablet) {
        margin-top: 10px !important;
    }
}

.heading {
    display: flex;
    flex-direction: row;
    text-align: left;

    @media screen and (max-width: $mq-tablet) {
        align-items: center;

        &__text {
            // padding-left: 10px !important;
        }
    }

    &__text {
        text-align: left;
        margin: 0;
        padding-left: 5px;
    }
}

.accordion {
    padding: 0 12px !important;
}

.data {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    justify-content: space-around;

    &__chart {
        max-height: 125px;
        max-width: 125px;
        padding: 5px;
    }

    &__details {
        padding: 0 5px;

        &--container {
            display: flex;
            flex-direction: row;
            height: 30px;
            margin: 5px 0;
            align-items: center;

            .colorBlock {
                height: 18px;
                width: 18px;
                border-radius: 4px;
            }

            .nameBlock {
                flex-grow: 1;
                padding: 0 0 0 5px;
                text-align: left;
                font-size: 11px;
            }

            .valueBlock {
                flex-grow: 1;
                padding: 0 5px 0 10px;
                text-align: right;
                font-size: 11px;
            }
        }
    }
}

.calorie-toggle-container {
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f1f1f1;
    margin: 0 10px 10px 10px;
    @media screen and (max-width: $mq-tablet) {
        margin: 0 0 10px 0;
    }
}

.calorieIntro {
    display: flex;
    color: #4f5f6c;
    font-weight: 500;
    font-size: 14px;
    line-height: 146%;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
    color: #6b787e;
    @media screen and (max-width: $mq-tablet) {
        margin-top: 15px;
    }
}

.buildMenuLink {
    font-size: 13px;
    text-align: center;
    @media screen and (max-width: $mq-tablet) {
        padding-top: 10px;
    }
    a {
        color: green;
    }
}

.daysAndPeople {
    color: #909da6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
}

.small {
    font-size: 11px !important;
    text-align: left;
    padding-top: 10px;
}

.viewMore {
    display: flex;
    align-items: center;
}

.updateButton {
    color: #233747;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
}

.view {
    font-size: 14px;
    @media screen and (max-width: $mq-tablet) {
        font-size: 12px;
    }
}

.MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd {
    transform: unset !important;
}

.MuiAccordion-root {
    position: unset !important;

    @media screen and (max-width: $mq-tablet) {
        padding: unset !important;
        margin: 0 20px;
    }
}

.MuiPaper-elevation1 {
    box-shadow: unset !important;
}

.goalHeader {
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media screen and (max-width: $mq-tablet) {
        font-size: 12px;
    }
}

.goalValue {
    padding-left: 5px;
}

.goleDetails {
    padding: 0px 9px 6px;
    width: 100%;

    @media screen and (max-width: 757px) {
        // padding: 11px 9px 6px;
    }
}

.goleContainer {
    font-size: 14px;
    font-weight: 400;
    float: left;
    width: 100%;
    padding-bottom: 4px;
    padding-left: 6px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: 757px) {
        // padding-left: 14px;
    }

    .goleWrapper {
        width: 76%;
        padding: 0px 3px 4px 0px;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 13px;
        display: flex;

        @media screen and (max-width: 757px) {
            width: 100%;
            // margin-left: 47px;
            // width: 60%;
        }
    }

    .goalServingInput {
        margin-left: 5px;
        div {
            // padding-right: 12px;
            // width: 20px;
        }
    }
}

.goalNumber {
    color: #909da6;
    font-size: 14px;
    padding-left: 4px;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
}

.goalTitle {
    color: #909da6;
    font-size: 14px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
}

.addDayContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 25px;
    flex-wrap: wrap;
    padding: 0 0 10px 5px;
    color: #909da6;
    font-size: 14px;
}

.addDayTitle {
    color: #909da6;
    margin-right: 2px;
}

.addDayInput {
    height: 25px;
    width: 30px;
    display: flex;
    border: none;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #f7eeee;
    color: #909da6;
    margin-left: 5px;
    margin-right: 5px;
}

.addOutsideCalories {
    height: 25px;
    width: 50px;
    display: flex;
    border: none;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #f7eeee;
    color: #909da6;
    margin-left: 5px;
    margin-right: 5px;
}
