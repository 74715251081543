@import 'variables.scss';

.groceriesContainer {
    display: flex;
    justify-content: center;
}

.groceriesContent {
    width: calc(100% - 384px);
    max-width: 1048px;
}

.groceriesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 31px 24px 24px 40px;

    & > div:nth-child(2) {
        & > div {
            justify-content: flex-end;
        }
    }
    .printShopping {
        cursor: pointer;
        color: #909dae;
        display: flex;
        text-decoration: none;
        text-align: right;
        & > div {
            display: flex;
            & > span {
                display: flex;
                font-family: Work Sans;
                margin: 0px 7px;
                font-size: 16px;
                color: #909dae;
                font-weight: 400;
                text-decoration: none;
                line-height: 23px;
                letter-spacing: -0.4000000059604645px;
                text-align: right;
            }
        }
    }
}
.headerTitle {
    & > div {
        & > h3 {
            margin: 0;
        }
        & > span {
            display: flex;
            text-align: left;
            padding-top: 10px;
        }
    }
}

.count {
    font-weight: 700;
    color: #82bd73;
}

.tooltip {
    display: flex;
}

.groceriesInfo {
    background-color: #fafafa;
    margin: 31px 24px 15px 40px;
    display: flex;
    justify-content: space-between;
    padding: 18px;
}
.groceryStore {
    width: 100%;
    flex-direction: column;
    .groceriesCount {
        display: flex;
        align-items: center;
        font-size: 18px;
        padding-bottom: 17px;
        border-bottom: 1px solid #e8e8e8;
        & > div {
            display: flex;
            & > div {
                margin-right: 8px;
            }
            & > div:first-child {
                font-weight: 600;
                color: #0d2120;
            }
        }
        & > div:last-child {
            color: #82bd73;
            font-size: 13px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .storeDetail {
        margin: 15px 0 10px 0;
        font-size: 14px;
        color: #0d2120;
        text-align: left;
        .storeDetailHeader {
            display: flex;
            align-items: center;
            .infoIcon {
                margin-left: 1%;
                color: #233747;
                padding-right: 5px;
                margin-bottom: 12px;
            }
        }
        .stores {
            display: flex;
            font-size: 13px;
            margin-top: 10px;

            & > div {
                cursor: pointer;
                & > span {
                    margin-right: 10px;
                }
                display: flex;
                align-items: center;
                margin-right: 7px;
                padding: 8px;
                background-color: white;
                border: 1px solid #dedede;
                border-radius: 15px;
            }
        }
    }
}
.summaryContainer {
    padding: 15px 0 0 0;
    margin: 0 15px 20px 40px;
    background-color: #fafafa;
}
.sectionOneContainer {
    padding: 20px 30px;
    text-align: left;
    border-bottom: 1px solid #e5eaed;
}
.sectionTwoContainer {
    padding: 20px 30px;
    text-align: left;
}
.link {
    margin-top: 5px;
    a {
        color: #82bd73;
        span {
            display: flex;
        }
    }
}
.pricePerServing {
    font-size: 24px;
    color: #82bd73;
    font-weight: 600;
    line-height: 40px;
}
.sectionDisclaimer {
    font-size: 15px;
    margin-top: 2px;
}
.sectionTitle {
    font-size: 18px;
    font-weight: 600;
    color: #0d2120;
    text-align: left;
    line-height: 23px;
}
.sectionDescription {
    color: #0d2120;
    font-size: 14px;
    text-align: left;
}
.sectionBullets {
    li {
        line-height: 23px;
    }
}
.savingsOnTopOfGroceries {
    position: absolute;
    top: 56px;
    width: calc(100% - 65px);
    display: flex;
    justify-content: center;
}
.whiteBackground {
    padding: 30px;
    background-color: #fafafa;
}
.savingsContainer {
    margin: 10px;
    border: 1px solid #e5eaed;
    border-radius: 5px;
    padding: 40px;
    position: relative;
    background-color: #f7fafc;
    .content {
        display: flex;
    }
    .title {
        font-size: 15px;
        font-weight: bold;
    }
    .textContainer {
        margin-left: 10px;
        text-align: left;
    }
    .description {
        font-size: 13px;
    }
    .buttonContainer {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 80px);
        bottom: -30px;
    }
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        min-width: 90px;
        padding: 15px 30px;
        color: white;
        background-color: #82bd73;
        border-radius: 3px;
        cursor: pointer;
    }
}
.bonusRecipes {
    color: white;
    border-left: 1px solid #e8e8e8;
    padding-left: 18px;
    .content {
        border-radius: 6px;
        align-items: center;
        display: flex;
        background-color: #233747;
        font-weight: 500;
        padding: 35px 22px;
        .bonusInfo {
            display: flex;
            align-items: center;
            & > div:last-child {
                min-width: 180px;
                margin: 0 10px;
                font-size: 15px;
                text-align: initial;
                & > div {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
        .tryRecipes {
            font-size: 14px;
            min-width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            background-color: #82bd73;
            border-radius: 3px;
            cursor: pointer;
        }
    }
}

.groceriesTabsContainer {
    position: relative;
    padding: 15px 25px 0 40px;

    & > div {
        padding: 0;
    }
}

.warningNotification {
    position: sticky;
    bottom: 31px;
    border-radius: 8px;
}

.warningText {
    background: #eff5f9;
    margin: 0 24px;
    padding: 16px 48px 16px 24px;
    color: #4f5f6c;
    font-size: 14px;
    line-height: 146%;

    & > span {
        color: #82bd73;
    }
}

.blur {
    opacity: 0.3;
    filter: blur(4px);
}

.warningButton {
    position: absolute;
    top: calc(50% - 12px);
    right: 48px;
    color: #4f5f6c;
    cursor: pointer;
}

.groceriesLabel {
    padding: 10px 24px 15px 40px;
    margin: 8px 0 30px 0;
    & > div {
        text-align: left;
    }
}
.removeGrocerieButton {
    background-color: #fd891b;
    font-size: 14px;
    width: fit-content;
    font-weight: 500;
    box-shadow: 0px 0px 4px 0px sandybrown;
    color: white;
    padding: 15px 30px;
    border-radius: 7px;
    cursor: pointer;
    margin: 7px 0;
    text-align: center !important;
}

@media screen and (max-width: $contentWidth) {
    .extra {
        flex-direction: column;
    }

    .extraText {
        width: 100%;
    }
}

@media screen and (max-width: $mq-tablet) {
    .summaryContainer {
        margin: 0;
    }
    .savingsContainer {
        padding: 30px 25px;
        margin: 0;
        .buttonContainer {
            width: calc(100% - 60px);
        }
    }

    .groceriesHeader {
        align-items: unset;
        text-align: left;
    }
    // .groceriesInfo {
    // }
    .groceriesInfo {
        flex-direction: column;
        margin: 0;
        .groceriesCount {
            font-size: 16px;
            align-items: center;
            justify-content: space-between;
            & > div {
                & > div {
                    margin-top: 7px;
                }
                text-align: left;
                flex-direction: column;
            }
        }
    }
    .bonusRecipes {
        border-left: none;
        padding-left: 0px;
        margin-top: 20px;
        .content {
            justify-content: space-between;
            padding: 21px 20px;
            .bonusInfo {
                & > div:last-child {
                    min-width: unset;
                }
            }
        }
    }

    .headerTitle {
        width: 100%;
        & > div {
            text-align: left;

            h3 {
                font-size: 24px;
                font-weight: bold;
            }
        }
    }

    .printShopping {
        padding: 10px;
    }

    .groceriesContent {
        width: calc(100% - 384px);
        max-width: 1048px;
    }
}

@media screen and (max-width: $mq-tablet) {
    .groceriesContent {
        width: 100%;
    }
    .printShopping {
        position: absolute;
        padding: 8px;
        right: 20px;
    }
}

@media screen and (max-width: $mq-mobile) {
    .groceriesHeader {
        border-bottom: unset;
        padding: 30px 16px 10px 18px;
        & > div:nth-child(1) {
            & > div:nth-child(2) {
                line-height: 120%;
            }
        }

        & > div:nth-child(2) {
            & > div {
                margin: 0;
            }
        }
    }
    .groceriesInfo {
        margin: 0;
        .groceriesCount {
            font-size: 16px;
            align-items: center;
            justify-content: space-between;
            & > div {
                & > div {
                    margin-top: 7px;
                }
                text-align: left;
                flex-direction: column;
            }
        }
    }
    .bonusRecipes {
        border-left: none;
        padding-left: 0px;
    }
    .warningNotification {
        margin: 0;

        .warningText {
            padding: 12px 44px 12px 20px;
            font-size: 10px;
            line-height: 144%;
            text-align: left;
            margin: 0;
        }

        .warningButton {
            right: 20px;
            color: #233747;
        }
    }

    .groceriesTabsContainer {
        padding: 20px 16px 0;
    }
    .savingsOnTopOfGroceries {
        width: calc(100% - 32px);
    }
    .groceriesLabel {
        padding: 5px 16px;
    }
    .removeGrocerieButton {
        width: 100%;
    }
}

@media screen and (max-width: $mq-tablet) {
    .groceriesHeader {
        align-items: unset;
        text-align: left;
    }

    .headerTitle {
        & > div {
            text-align: left;

            h3 {
                font-size: 24px;
                font-weight: bold;
            }
            & > div:nth-child(2) {
                width: 88%;
            }
        }
    }
}
