@import 'variables.scss';

.modalFilter {
    max-width: 1024px !important;
    max-height: 740px !important;
    overflow: hidden;
    border-radius: 16px;
}
.content {
    width: 1024px;
    height: 592px;
    padding: 0px !important;
}
.contentTablet {
    max-width: 1024px;
    height: 592px;
    padding: 0px !important;
}
.allContent {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}
.dividedContent {
    padding: 30px;
}

@media all and (max-width: $mq-mobile) {
    .modalFilter {
        margin: 0 !important;
        height: 100%;
        border-radius: unset !important;
        width: 100%;
        max-width: unset !important;
        max-height: unset !important;
    }
    .dividedContent {
        padding: 0 30px;
    }
    .content {
        border-radius: unset !important;
        width: 100%;
        height: unset !important;
        max-width: unset !important;
    }
    .allContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
