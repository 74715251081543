.shareButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    @media screen and (max-width: 767px) {
        margin-left: 10px;
    }

    & > span {
        color: #4f5f6c;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
    }

    & > div,
    & > button {
        margin: 0 8px;
        img {
            width: 32px;
            height: 32px;
        }
    }
}

.closeButton {
    &:hover {
        cursor: pointer;
    }
}

.fabCopyLink {
    box-shadow: unset !important;
    background: unset !important;
    width: unset !important;
    height: unset !important;

    &:hover {
        background: unset !important;
    }

    &:active {
        box-shadow: unset !important;
    }

    & > span:nth-child(2) {
        height: 24px;
        top: 6px;
    }
}

.fab {
    &:hover {
        cursor: pointer;
    }
}

.titleLogo {
    background-size: cover;
    height: 31px;
    width: 159px;
}

.imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% 100% 0 0/ 100% 100%;
    margin-top: -50px;
    background: #fff;
    .imgLogo {
        width: 101px;
        height: 101px;
        background: #82bd73;
        border: 3px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -50px;
        border-radius: 50%;
    }
}

.userInfo {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #82bd73;
    margin-bottom: 18px;
    letter-spacing: -0.69px;
}
.line {
    width: 32px;
    height: 3px;
    background: #82bd73;
    border-radius: 50px;
    text-align: center;
    margin: 15px auto;
}
.listWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 200px);
    @media screen and (max-width: 767px) {
        height: calc(100% - 100px);
    }
    flex: 1;
    .listItem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-height: 300px;
        gap: 24px;
        flex: 1;
        overflow: auto;
        @media screen and (max-height: 767px) {
            max-height: 211px;
            gap: 14px;
        }
    }
    .socialLink {
        display: flex;
        width: 100%;
        height: 112px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #f9f9f9;
        border-radius: 0px 0px 20px 20px;
        padding: 20px 0 30px;
        margin-top: 30px;
        @media screen and (max-width: 767px) {
            height: 90px;
            display: flex;
            flex-direction: row;
        }
        .socialLinkText {
            margin-bottom: 12px;
            margin-top: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: #233747;
            @media screen and (max-width: 767px) {
                margin-bottom: 0px;
            }
        }
    }
}
