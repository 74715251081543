$color-primary: #489800;

$mq-desktop: 1540px;
$mq-medium-desktop: 1440px;
$mq-tablet: 960px;
$mq-medium-tablet: 880px;
$mq-tablet: 1024px;
$mq-small-tablet: 768px;
$mq-small: 600px;
$mq-mobile: 480px;
$mq-small-mobile: 375px;

$contentWidth: 1160px;
$contentWidthTablet: 770px;
