@import 'variables.scss';

.imageContainer {
    height: 400px;
    width: 100%;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.6));
}

.overlayForMobileClicking {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

@media all and (max-width: $mq-mobile) {
    .overlayForMobileClicking {
        display: block;
        z-index: 1;
    }
    .imageContainer {
        height: 360px;
    }
}
