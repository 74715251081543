@import './variables.scss';

html {
    height: 100%;
    margin: 0;
    * {
        box-sizing: border-box;
    }

    *:focus {
        outline: none;
    }
}

body {
    height: 100%;
    margin: 0;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // font-family: "Dosis", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.arrowButton {
    &:hover {
        background-color: rgba(123, 201, 65, 0.1) !important;
        color: $color-primary !important;
    }
}

.addDeleteControls {
    flex-wrap: none !important;
    .button {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        color: #489800;
        flex: none !important;
    }

    .quantity {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 1.4px;
        text-align: center;
        color: rgba(13, 19, 0, 0.6);
    }

    .minus {
        margin-right: 12px;
    }

    .add {
        background-color: #7bc941;
        color: #ffffff;
        margin-left: 12px;

        &.touched {
            background-color: #ffffff;
            color: #489800;

            &:hover {
                text-decoration: none;
                background-color: #d5d5d5;
            }
        }
    }
}

.primaryColor {
    color: $color-primary;
}

@media all and (max-width: $mq-mobile) {
    .addDeleteControls {
        flex: none;
        .add {
            margin-left: 3px;
        }
        .minus {
            margin-right: 3px;
        }
    }
}

.faq-list {
    text-align: left;
    a {
        color: inherit;
    }
}

.heading-page {
    text-align: center;
    font-size: 24px;
    margin: 15px 0 5px;
}

#root {
    height: 100%;
    margin: 0;
}

#tawkchat-container iframe {
    bottom: 14px !important;
    @media screen and (max-width: 500px) {
        bottom: 46px !important;
    }
}

@media print {
    iframe {
        display: none !important;
    }
}

.__react_component_tooltip.type-light.border.place-bottom:before {
    border-bottom: 8px solid #dadada;
}

.__react_component_tooltip.type-light.border.place-left:before {
    border-left: 8px solid #dadada;
}

#panel1a-header {
    max-width: 560px;
    margin: auto;
}

#root > span > div > div.OrderSummaryPage_summaryPage__15_mY > div:nth-child(5) > div:nth-child(2):before {
    max-width: 560px;
    margin: auto;
}

div.swiper-button-next,
div.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    &::after {
        font-size: 20px;
        color: white;
    }
}

div.swiper-button-prev.swiper-button-disabled,
div.swiper-button-next.swiper-button-disabled {
    display: none;
}

[placeholder] {
    text-overflow: ellipsis;
}

.MuiDialog-container {
    height: 50%;
}

.dialog-wrapper {
    .MuiDialog-container {
        .MuiDialog-paper {
            width: 470px;
            height: 276px;
            border-radius: 16px;
            padding: 24px;
            @media screen and (max-width: $mq-mobile) {
                width: 335px;
                margin: 0;
            }
        }
    }
}
