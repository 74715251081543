@import 'variables.scss';

.container {
    box-shadow: none;
    margin-top: 5px !important;
    @media screen and (max-width: $mq-tablet) {
        margin-top: 10px !important;
    }
}
.heading {
    display: flex;
    flex-direction: row;

    &__text {
        margin: 0;
        padding: 0 10px 0 5px;
    }
}

.accordion {
    padding: 0 12px !important;
}

.accordion-groceries {
    padding: 0 !important;
    @media screen and (max-width: $mq-tablet) {
        padding: 0 12px !important;
    }
}

.viewMore {
    display: flex;
    align-items: center;
}
.view {
    font-size: 14px;
}
.MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd {
    transform: unset !important;
}
.MuiAccordion-root {
    position: unset !important;
    @media screen and (max-width: $mq-tablet) {
        padding: unset !important;
        // margin: 0 20px;
    }
}
.MuiPaper-elevation1 {
    box-shadow: unset !important;
}

.groceriesDetails {
    width: 100%;
    padding: 0;
}

.groceriesContainerBox {
    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    float: left;
    width: 50%;
    padding: 10px;

    .groceriesContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 14px;
    }

    .groceriesImg {
        float: left;
        width: 45px;
        height: 40px;
        object-fit: cover;
        border-radius: 4px;
        margin-right: 8px;
    }
    .groceriesName {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100px;
        white-space: pre-line;
        text-align: left;
        font-weight: 400;
        color: #0d2120;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 14px;
    }
    .groceriesQuantity {
        color: #bfc8cd;
    }

    @media screen and (max-width: $mq-tablet) {
        padding: 5px 0px;
    }
}

.ingredient-detail-accordion {
    .accordion .accordion-groceries {
        &.Mui-expanded {
            min-height: 48px;
        }
        .MuiAccordionSummary-content {
            &.Mui-expanded {
                margin: 2px 0;
            }
        }
        .MuiButtonBase-root {
            padding: 0 12px;
        }
    }
    .accordion-detail {
        overflow-x: hidden;
        h1 {
            margin: 0;
        }
        @media screen and (max-width: $mq-tablet) {
            max-height: unset;
        }
    }
}
.MuiAccordionDetails-root {
    @media screen and (max-width: $mq-tablet) {
        padding: 15px 0px !important;
    }
}
