.shareButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
        color: #4f5f6c;
        font-weight: 580;
        font-size: 14px;
        line-height: 150%;
    }

    & > div,
    & > button {
        height: 24px;
        width: 24px;
        margin: 0 8px;
    }
}
.fab {
    box-shadow: unset !important;
    background: unset !important;
    min-height: unset !important;

    &:hover {
        background: unset !important;
    }

    &:active {
        box-shadow: unset !important;
    }

    & > span:nth-child(2) {
        height: 24px;
        top: 6px;
    }
}
.urlField {
    color: #3e4d5a;
    font-weight: 520;
    font-size: 14px;
    width: 200px;
    padding: 0px 8px;
    line-height: 150%;
    background-color: #f0fff0;
}
.tooltip {
    text-transform: capitalize;
}
