@import 'variables.scss';
.groceryHeader {
    min-height: 112px;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 32px 48px 32px 40px;
    justify-content: space-between;
    border-bottom: 1px solid #eff5f9;

    .headerTitle {
        color: #0d2120;
        letter-spacing: -0.69px;
        font-weight: bold;
        font-size: 32px;
        line-height: 150%;
        display: flex;
    }
    .backbtn {
        display: flex;
    }

    .headerSubTitle {
        line-height: 150%;
        color: #909da6;
        font-size: 16px;
    }

    .buttonGroup {
        display: flex;
        align-items: center;

        & > div {
            margin-right: 15px !important;

            button {
                width: unset;
            }
        }
    }
}

@media screen and (max-width: $mq-mobile) {
    .groceryHeader {
        display: block;
        display: block;
        padding: 23px 20px 20px;

        .headerTitle {
            font-size: 24px;
        }

        .headerSubTitle {
            font-size: 12px;
            padding-bottom: 20px;
        }
    }
}
